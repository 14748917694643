<template>
<div>
    <NavComp/>
    <div class="container">
        <b-tabs type="is-boxed" expanded>
            <b-tab-item label="Content" icon='server' icon-pack="fas">
                <h1>This is the list of sources for the plant lists</h1>
                <!--<a href="https://www.gardeningchannel.com/list-of-herbs-from-a-to-z/">List of all the Herbs</a> + some others -->
                <a href="https://www.kraeuter-buch.de/kraeuter/alle-kraeuter">Neue Liste an Kräutern</a>
                <br>  
                <a href="http://fruitlife.de/inside.php?in=encyclopedia/fruithomeminiliste-de.htm">Aktuelle Liste von Früchten</a>
                und
                <a href="https://www.taschenhirn.de/mensch-und-natur/obstsorten/"> Die hier</a>
                <br>
                <a href="https://www.garten-treffpunkt.de/lexikon/gemuese.aspx#body">Neue Gemüseliste</a>
                <!--<a href="https://en.wikipedia.org/wiki/List_of_vegetables">List of Vegetables and Fruits</a> -->
                <br>
                <!-- <a href="https://simple.wikipedia.org/wiki/List_of_vegetables">Other list of vegetables</a>
                <br>
                <a href="https://www.halfyourplate.ca/fruits-and-veggies/veggies-a-z/">And another List of vegetables</a> -->
                <br>
                <!--<a href="https://succulentalley.com/types-of-succulents-with-pictures/">List of succulents</a> -->
                <a href="https://www.gartenlexikon.de/steingartenpflanzen/">Steingartenpflanzen</a>
                <a href="https://www.plantopedia.de/steingartenpflanzen/">Steingartenpflanzen</a>
                <br>
                <a href="https://www.blume2000.de/blumenlexikon/">Neues Blumenlexikon</a>
                <br>
                <a href="https://www.plantopedia.de/blumenarten-mit-bild/">weitere Blumenliste in verwendung</a>
              <!-- <a href="https://www.gardeningchannel.com/list-of-flower-names/">List of flowers</a> -->
                <br>
                <!--<a href="https://www.homestratosphere.com/types-of-trees/">List of Trees</a>-->
                <a href="https://www.garten-treffpunkt.de/lexikon/baeume.aspx">Liste mit Bäumen</a>
                <a href="https://www.baumkunde.de/baumlisten/baumliste_az.php"> Alle Bäume</a> *Nicht verwendet
                <a href="https://www.baumkunde.de/haeufigste-baeume-deutschland.php">Weitere Baumliste</a>
                <br>
                <a href="https://www.plantopedia.de/bluehende-garten-straeucher/">Busch Liste</a>
                <a href="https://www.gartenlexikon.de/straeucher-sonnige-standorte/">Busch Liste</a>
                <a href="https://www.hausgarten.net/pflanzen/baeume/straeucher-fuer-gaerten.html">Schnellwachsende Büsche</a>
                <br>
                <br>
                <!--
                <h3>Weitere Pflanzenlisten</h3>
                <br>
                <a href="http://www.theplantlist.org/">weitere Pflanzenliste</a>
                <br>
                <a href="http://www.gflora.com/index.php?cmd=genus">Weitere Pflanzenliste</a> -->
                <br>
                <br>
                <h3><a href="https://www.wikipedia.org/">Wikipedia</a> API inclusion</h3>
            </b-tab-item>
            <b-tab-item label="Pflanzen Listen" icon="leaf" icon-pack="fas">
                <div v-if="!mobile">
                    <b-tabs
                    type="is-boxed"
                    vertical
                    >
                        <b-tab-item label="Fruits 🍓" icon="">
                                <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedFruits"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="fruitsLength"
                            :current.sync ="currentFruits"
                            order="is-centered"
                            :simple="isSimple"
                            :per-page="perPage"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Herbs 🌿" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedHerbs"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="herbsLength"
                            :current.sync ="currentHerbs"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Flowers 🌺" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedFlowers"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="flowersLength"
                            :current.sync ="currentFlowers"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Trees 🌲" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedTrees"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="treesLength"
                            :current.sync ="currentTrees"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Vegetables 🥕" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedVegetables"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="vegetablesLength"
                            :current.sync ="currentVegetables"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Succulents 🌵" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedSucculents"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="succulentsLength"
                            :current.sync ="currentSucculents"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                        <b-tab-item label="Bushes 🌳" icon="">
                            <b-collapse 
                                    class="card" 
                                    animation="slide"
                                    v-for="(item,index) of paginatedBushes"
                                    :key="index"
                                    :open="isOpen == index"
                                    @open="isOpen = index"
                                >
                                    <template #trigger="props">
                                        <div
                                            class="card-header"
                                            role="button"
                                        >
                                            <p class="card-header-title">
                                                {{ item }}
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon
                                                    :icon="props.open ? 'menu-down' : 'menu-up'">
                                                </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <div class="card-content">
                                        <div class="content">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                                Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                                Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                        </div>
                                    </div>
                                </b-collapse>
                            <b-pagination
                            :total="bushesLength"
                            :current.sync ="currentBushes"
                            order="is-centered"
                            :simple="isSimple"
                            per-page="10"
                            icon-prev="chevron-left"
                            icon-next="chevron-right"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page">
                            </b-pagination>
                        </b-tab-item>
                    </b-tabs>
                </div>
                <div v-else>
                    <b-field label="Choose type">
                        <b-select placeholder="Select a character" expanded v-model="selectorType">
                            <option value="fruits">Fruits 🍓</option>
                            <option value="herbs">Herbs 🌿</option>
                            <option value="flowers">Flowers 🌺</option>
                            <option value="trees">Trees 🌲</option>
                            <option value="vegetables">Vegetables 🥕</option>
                            <option value="succulents">Succulents 🌵</option>
                            <option value="bushes">Bushes 🌳</option>
                        </b-select>
                    </b-field>
                    <div v-if="selectorType=='fruits'">
                            <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedFruits"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="fruitsLength"
                          :current.sync ="currentFruits"
                          order="is-centered"
                          :simple="isSimple"
                          :per-page="perPage"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='herbs'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedHerbs"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="herbsLength"
                          :current.sync ="currentHerbs"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='flowers'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedFlowers"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="flowersLength"
                          :current.sync ="currentFlowers"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='trees'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedTrees"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="treesLength"
                          :current.sync ="currentTrees"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='vegetables'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedVegetables"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="vegetablesLength"
                          :current.sync ="currentVegetables"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='succulents'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedSucculents"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="succulentsLength"
                          :current.sync ="currentSucculents"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                    <div v-if="selectorType=='bushes'">
                        <b-collapse 
                                class="card" 
                                animation="slide"
                                v-for="(item,index) of paginatedBushes"
                                :key="index"
                                :open="isOpen == index"
                                @open="isOpen = index"
                            >
                                <template #trigger="props">
                                    <div
                                        class="card-header"
                                        role="button"
                                    >
                                        <p class="card-header-title">
                                            {{ item }}
                                        </p>
                                        <a class="card-header-icon">
                                            <b-icon
                                                :icon="props.open ? 'menu-down' : 'menu-up'">
                                            </b-icon>
                                        </a>
                                    </div>
                                </template>
                                <div class="card-content">
                                    <div class="content">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br/>
                                            Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. <br/>
                                            Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.
                                    </div>
                                </div>
                            </b-collapse>
                        <b-pagination
                          :total="bushesLength"
                          :current.sync ="currentBushes"
                          order="is-centered"
                          :simple="isSimple"
                          per-page="10"
                          icon-prev="chevron-left"
                          icon-next="chevron-right"
                          aria-next-label="Next page"
                          aria-previous-label="Previous page"
                          aria-page-label="Page"
                          aria-current-label="Current page">
                        </b-pagination>
                    </div>
                </div>
            </b-tab-item>
        </b-tabs>
    </div>
</div>
</template>

<script>
import NavComp from "@/components/NavComp.vue"

//importing lists
import herbList from "@/db/data/lists/herb_list.js";
import fruitList from "@/db/data/lists/fruit_list.js";
import flowerList from "@/db/data/lists/flower_list.js";
import bushList from "@/db/data/lists/bush_list.js";
import succulentList from "@/db/data/lists/succulent_list.js";
import treeList from "@/db/data/lists/tree_list.js";
import vegetableList from "@/db/data/lists/vegetable_list.js";


export default {
  components: {
    NavComp
  },
  data() {
    return {
      openTiles: false,
      isSimple: true,
      perPage: 10,

      isOpen: -1,

      currentHerbs: 1,
      currentFruits: 1,
      currentFlowers: 1,
      currentVegetables: 1,
      currentSucculents: 1,
      currentTrees: 1,
      currentBushes: 1,

      herbsLength: 0,
      fruitsLength: 0,
      flowersLength: 0,
      vegetablesLength: 0,
      succulentsLength: 0,
      treesLength: 0,
      bushesLength: 0,

      selectorType: "",
    }
  },
  computed: {
        mobile: function() {
            let width = window.innerWidth || document.body.clientWidth;
            if(width > 1216){
                return false;
            }
            return true;
        },
        //plantlists
        herbs: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.herbsLength = herbList.en.herbs.length;
                    return herbList.en.herbs;
                case "de":
                    this.herbsLength = herbList.de.herbs.length;
                    return herbList.de.herbs;
                default:
                    this.herbsLength = herbList.en.herbs.length;
                    return herbList.en.herbs;
            }
        },
        fruits: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.fruitsLength = fruitList.en.fruits.length;
                    return fruitList.en.fruits;
                case "de":
                    this.fruitsLength = fruitList.de.fruits.length;
                    return fruitList.de.fruits;
                default:
                    this.fruitsLength = fruitList.en.fruits.length;
                    return fruitList.en.fruits;
            }
        },
        flowers: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.flowersLength = flowerList.en.flowers.length;
                    return flowerList.en.flowers;
                case "de":
                    this.flowersLength = flowerList.de.flowers.length;
                    return flowerList.de.flowers;
                default:
                    this.flowersLength = flowerList.en.flowers.length;
                    return flowerList.en.flowers;
            }
        },
        bushes: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.bushesLength = bushList.en.bushes.length;
                    return bushList.en.bushes;
                case "de":
                    this.bushesLength = bushList.de.bushes.length;
                    return bushList.de.bushes;
                default:
                    this.bushesLength = bushList.en.bushes.length;
                    return bushList.en.bushes;
            }
        },
        succulents: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.succulentsLength = succulentList.en.succulents.length;
                    return succulentList.en.succulents;
                case "de":
                    this.succulentsLength = succulentList.de.succulents.length;
                    return succulentList.de.succulents;
                default:
                    this.succulentsLength = succulentList.en.succulents.length;
                    return succulentList.en.succulents;
            }
        },
        trees: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.treesLength = treeList.en.trees.length;
                    return treeList.en.trees;
                case "de":
                    this.treesLength = treeList.de.trees.length;
                    return treeList.de.trees;
                default:
                    this.treesLength = treeList.en.trees.length;
                    return treeList.en.trees;
            }
        },
        vegetables: function() {
            switch(this.$i18n.locale){
                case "en":
                    this.vegetablesLength = vegetableList.en.vegetables.length;
                    console.log(this.vegetablesLength)
                    return vegetableList.en.vegetables;
                case "de":
                    this.vegetablesLength = vegetableList.de.vegetables.length;
                    return vegetableList.de.vegetables;
                default:
                    this.vegetablesLength = vegetableList.en.vegetables.length;
                    return vegetableList.en.vegetables;
            }
        },
        paginatedFruits() {
            let page_number = this.currentFruits-1
            return this.fruits.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedHerbs() {
            let page_number = this.currentHerbs-1
            return this.herbs.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedFlowers() {
            let page_number = this.currentFlowers-1
            return this.flowers.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedBushes() {
            let page_number = this.currentBushes-1
            return this.bushes.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedSucculents() {
            let page_number = this.currentSucculents-1
            return this.succulents.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedTrees() {
            let page_number = this.currentTrees-1
            return this.trees.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        },
        paginatedVegetables() {
            let page_number = this.currentVegetables-1
            return this.vegetables.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
        }
  }
}
</script>

<style scoped>
h1 {
  font-weight: bold;
  font-size: 32px;
}
h3 {
  font-weight: bolder;
  font-size: 24px;
}
.card {
  margin-bottom: 5px;
}


</style>